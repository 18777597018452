<template>
    <div class="main">
        <el-row>
            <el-col :span="5">
                <div>
                    <el-menu class="leftside" default-active="1" background-color="#545c64" text-color="#fff"
                        active-text-color="#409eff">
                        <el-submenu index="1">
                            <template slot="title">
                                <i class="el-icon-notebook-1"></i>
                                <span>博客</span>
                            </template>
                            <el-menu-item-group>

                                <router-link class="rlink" to="/admin/write">
                                    <el-menu-item index="1-1" @click="blogstate">
                                        <i class="el-icon-edit-outline"></i>编辑
                                    </el-menu-item>
                                </router-link>

                                <router-link class="rlink" to="/admin/summary">
                                    <el-menu-item index="1-2">
                                        <i class="el-icon-notebook-2"></i>归档
                                    </el-menu-item>
                                </router-link>
                            </el-menu-item-group>
                        </el-submenu>

                        <el-submenu index="2">
                            <template slot="title">
                                <i class="el-icon-headset"></i>
                                <span>音乐</span>
                            </template>
                            <el-menu-item-group>
                                <router-link class="rlink" to="/admin/musicupload">
                                    <el-menu-item index="2-1" @click="musicstate">
                                        <i class="el-icon-edit-outline"></i>音乐上传
                                    </el-menu-item>
                                </router-link>
                                <router-link class="rlink" to="/admin/musiclist">
                                    <el-menu-item index="2-2">
                                        <i class="el-icon-notebook-2"></i>音乐列表
                                    </el-menu-item>
                                </router-link>
                            </el-menu-item-group>
                        </el-submenu>

                        <el-submenu index="3">
                            <template slot="title">
                                <i class="el-icon-s-management"></i>
                                <span>教学管理</span>
                            </template>
                            <el-menu-item-group>
                                <router-link class="rlink" to="/admin/Course">
                                    <el-menu-item index="3-1" @click="musicstate">
                                        <i class="el-icon-edit-outline"></i>发布
                                    </el-menu-item>
                                </router-link>
                                <router-link class="rlink" to="/admin/CourseData">
                                    <el-menu-item index="3-2">
                                        <i class="el-icon-notebook-2"></i>文章列表
                                    </el-menu-item>
                                </router-link>
                            </el-menu-item-group>
                        </el-submenu>

                        <router-link class="rlink" to="/admin/upload">
                        <el-menu-item index="2">
                            <i class="el-icon-upload"></i>
                            <span slot="title">文件上传</span>
                        </el-menu-item>
                    </router-link>

                        <router-link class="rlink" to="/admin/file">
                        <el-menu-item index="4">
                            <i class="el-icon-setting"></i>
                            <span slot="title">文件管理</span>
                        </el-menu-item>
                    </router-link>

                        
                    </el-menu>
                </div>
            </el-col>
            <el-col :span="19">
                <div id="routerpart">
                    <router-view v-if="showAgain"></router-view>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
export default {
    data() {
        return {
            showAgain: true
        }
    },
    methods: {
        blogstate() {
            this.$store.commit('blogState', false)
            this.showAgain = false
            this.$nextTick(() => {
                // dom元素更新后执行，因此这里能正确打印更改之后的值
                this.showAgain = true // 改变了的值
            })
        },
        musicstate() {
            this.$store.commit('musicState', false)
            this.showAgain = false
            this.$nextTick(() => {
                // dom元素更新后执行，因此这里能正确打印更改之后的值
                this.showAgain = true // 改变了的值
            })
        }
    }
}
</script>

<style >
.main {
    clear: both;
}

.leftside {
    height: 100vh;
    width: 20vw;
    background-color: rgb(84, 92, 100);
    overflow: hidden;
}

a {
    text-decoration: none !important;
}

.rlink {
    color: #fff;
}

/* .navhead, */
#routerpart {
    overflow: auto;
    height: 100vh;
    width: 100%;
}
</style>
